import React from 'react';
import logo from "./logo.svg";
import './About.css';

export const About = () => (
    <div className="About">
        <header className="About-header">
            <img src={logo} className="About-logo" alt="logo" />
            <p>
                About Mui Gui
            </p>
        </header>
    </div>
)
