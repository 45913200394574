import React from 'react';

import styled from 'styled-components';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap';


const Styles = styled.div`
    .navbar {
        background-color : green;
    }

    .navbar-brand, .navbar-nav .nav-link {
    color : #bbb;

    &:hover
    {
      color: white;
    }
  }`;


 export const NavigationBar = () => (
     <Styles>
         <Navbar expand="lg" bg="dark" variant="dark">
             <Navbar.Brand href="/">
                 <img
                     alt=""
                     src={"/favicon.ico"}
                     width="30"
                     height="30"
                     className="d-inline-block align-top"
                 />{' '}
                 Mui Gui
             </Navbar.Brand>
             <Navbar.Toggle aria-controls="basic-navbar-nav"/>
             <Navbar.Collapse id="basic-navbar-nav">
                 <Nav className="me-auto">
                     <NavDropdown title="Info" id="basic-nav-dropdown">
                         <NavDropdown.Item  href="/about">About</NavDropdown.Item>
                         <NavDropdown.Divider />
                         <NavDropdown.Item  href="/contact">Contact</NavDropdown.Item>
                     </NavDropdown>

                 </Nav>
            </Navbar.Collapse>
        </Navbar>
     </Styles>
 )








