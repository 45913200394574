import React from 'react';
import './Footer.css';

export const Footer = () => {
    return (
        <div className={"main-footer"}>
            <div className={"container"}>
                    <hr/>
                    &copy; {new Date().getFullYear()} Expedited Dual Commerce LLC
            </div>
        </div>
    )
}
